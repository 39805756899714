/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'GlobalStyles',
        import: () => import('@stories/Widgets/Global/GlobalStyles/GlobalStyles'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Header',
        import: () => import('@stories/Widgets/Global/Header/Header'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'HeaderRevamped',
        import: () => import('@stories/Widgets/Global/HeaderRevamped/HeaderRevamped'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'HeaderReduced',
        import: () => import('@stories/Widgets/Global/HeaderReduced/HeaderReduced'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Footer',
        import: () => import('@stories/Widgets/Global/Footer/Footer'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FooterReduced',
        import: () => import('@stories/Widgets/Global/FooterReduced/FooterReduced'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AccordionContent',
        import: () => import('@stories/Widgets/AccordionContent/AccordionContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Accreditations',
        import: () => import('@stories/Widgets/Accreditations/Accreditations'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Breadcrumbs',
        import: () => import('@stories/Widgets/Global/Breadcrumbs/Breadcrumbs'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContentCardList',
        import: () => import('@stories/Widgets/ContentCardList/ContentCardList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CtaSection',
        import: () => import('@stories/Widgets/CtaSection/CtaSection'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'DocumentListing',
        import: () => import('@stories/Widgets/DocumentListing/DocumentListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FormBlock',
        import: () => import('@stories/Widgets/FormBlock/FormBlock'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FormModal',
        import: () => import('@stories/Widgets/FormModal/FormModal'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'IconCardList',
        import: () => import('@stories/Widgets/IconCardList/IconCardList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageBlock',
        import: () => import('@stories/Widgets/ImageBlock/ImageBlock'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageCarousel',
        import: () => import('@stories/Widgets/ImageCarousel/ImageCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LogoCarousel',
        import: () => import('@stories/Widgets/LogoCarousel/LogoCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InnerPageHero',
        import: () => import('@stories/Widgets/InnerPageHero/InnerPageHero'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LandingPageHero',
        import: () => import('@stories/Widgets/LandingPageHero/LandingPageHero'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'QuoteBlock',
        import: () => import('@stories/Widgets/QuoteBlock/QuoteBlock'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'QuoteBlockRedesign',
        import: () => import('@stories/Widgets/QuoteBlockRedesign/QuoteBlockRedesign'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ResourceCarousel',
        import: () => import('@stories/Widgets/ResourceCarousel/ResourceCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ResourceListing',
        import: () => import('@stories/Widgets/ResourceListing/ResourceListing'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RichText',
        import: () => import('@stories/Widgets/RichText/RichText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SearchResults',
        import: () => import('@stories/Widgets/SearchResults/SearchResults'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContent',
        import: () => import('@stories/Widgets/SplitContent/SplitContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'StatisticCarousel',
        import: () => import('@stories/Widgets/StatisticCarousel/StatisticCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VideoBlock',
        import: () => import('@stories/Widgets/VideoBlock/VideoBlock'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HtmlSitemap',
        import: () => import('@stories/Widgets/HtmlSitemap/HtmlSitemap'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PortalLogin',
        import: () => import('@stories/Widgets/PortalLogin/PortalLogin'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ForgottenPassword',
        import: () => import('@stories/Widgets/ForgottenPassword/ForgottenPassword'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PasswordReset',
        import: () => import('@stories/Widgets/PasswordReset/PasswordReset'),
        config: {
            hydrate: 'in-view',
        },
    },
];
